import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Avatar, Text, Select, Button, HStack, VStack, useToast, IconButton, Flex
} from '@chakra-ui/react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import { collection, getDocs, query, where, limit, startAfter } from 'firebase/firestore';
import { firestore } from '../firebaseConfig'; // Firestore config
import NavBar from './NavBar'; // NavBar component
import { ArrowRightIcon, ArrowLeftIcon } from '@chakra-ui/icons'; // Pagination icons

const RecipeList = ({ currentUser }) => {
  const [recipes, setRecipes] = useState([]);
  const [beerTypes, setBeerTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [filterBeerType, setFilterBeerType] = useState('');
  const [filterUser, setFilterUser] = useState('');
  const [lastDoc, setLastDoc] = useState(null); // For pagination
  const [page, setPage] = useState(1);
  const toast = useToast();

  const RECIPES_PER_PAGE = 25;

  useEffect(() => {
    fetchRecipes();
    fetchBeerTypes();
    fetchUsers();
  }, []);

  const fetchRecipes = async () => {
    try {
      const recipesQuery = query(collection(firestore, 'recipes'), limit(RECIPES_PER_PAGE));
      const querySnapshot = await getDocs(recipesQuery);
      const recipeData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setRecipes(recipeData);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } catch (error) {
      toast({
        title: 'Error fetching recipes.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchBeerTypes = async () => {
    const querySnapshot = await getDocs(collection(firestore, 'beerTypes'));
    const beerTypesData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setBeerTypes(beerTypesData);
  };

  const fetchUsers = async () => {
    const querySnapshot = await getDocs(collection(firestore, 'users'));
    const usersData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setUsers(usersData);
  };

  const handleFilterChange = async () => {
    try {
      let recipesQuery = collection(firestore, 'recipes');
      if (filterBeerType) {
        recipesQuery = query(recipesQuery, where('beerType', '==', filterBeerType));
      }
      if (filterUser) {
        recipesQuery = query(recipesQuery, where('userId', '==', filterUser));
      }

      const querySnapshot = await getDocs(recipesQuery);
      const recipeData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setRecipes(recipeData);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } catch (error) {
      toast({
        title: 'Error applying filter.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNextPage = async () => {
    try {
      const nextQuery = query(
        collection(firestore, 'recipes'),
        limit(RECIPES_PER_PAGE),
        startAfter(lastDoc)
      );
      const querySnapshot = await getDocs(nextQuery);
      const recipeData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setRecipes(recipeData);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setPage(page + 1);
    } catch (error) {
      toast({
        title: 'Error fetching next page.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePreviousPage = async () => {
    // Logic to fetch the previous page, depending on Firestore pagination requirements.
    setPage(page - 1);
  };

  return (
    <Box p={5} mt={105}>
      <NavBar /> {/* Include the navigation bar */}
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        {/* Filter options */}
        <HStack spacing={4}>
          <Select placeholder="Filter by Beer Type" onChange={(e) => setFilterBeerType(e.target.value)}>
            {beerTypes.map((type) => (
              <option key={type.id} value={type.name}>
                {type.name}
              </option>
            ))}
          </Select>
          <Select placeholder="Filter by User" onChange={(e) => setFilterUser(e.target.value)}>
            {currentUser && <option value={currentUser.uid}>My Recipes</option>}
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </Select>
          <Button onClick={handleFilterChange} colorScheme="teal">
            Apply Filters
          </Button>
        </HStack>

        {/* Pagination */}
        <HStack spacing={2}>
          <IconButton
            icon={<ArrowLeftIcon />}
            onClick={handlePreviousPage}
            isDisabled={page === 1}
            aria-label="Previous Page"
          />
          <Text>Page {page}</Text>
          <IconButton
            icon={<ArrowRightIcon />}
            onClick={handleNextPage}
            aria-label="Next Page"
          />
        </HStack>
      </Flex>

      {/* Recipe List Table */}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Recipe Name</Th>
            <Th>Beer Type</Th>
            <Th>User</Th>
          </Tr>
        </Thead>
        <Tbody>
          {recipes.map((recipe) => (
            <Tr key={recipe.id}>
              <Td>
                <Link to={`/beer-recipe/${recipe.id}`}>{recipe.name}</Link> {/* Link to the AddRecipe page */}
              </Td>
              <Td>{recipe.beerType}</Td>
              <Td>
                <HStack spacing={3}>
                  <Avatar name={recipe.userName} src={recipe.userAvatar} />
                  <Text>{recipe.userName}</Text>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default RecipeList;
