import React, { useState } from 'react';
import {
  Box, Heading, Input, Button, VStack, Text, Link, FormControl, FormLabel, useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebaseConfig'; // Assuming firebase is configured
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import Footer from './Footer';
import Ziggy from './Ziggy';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const handleSignUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(firestore, 'users', user.uid), {
        email: user.email,
        avatarURL: '',
        firstName: '',
        lastName: '',
        bio: '',
      });

      toast({
        title: 'Account created.',
        description: "Welcome! You've successfully signed up.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate('/recipes'); // Redirect after sign-up
    } catch (error) {
      toast({
        title: 'Sign Up Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
    <Box
      minH="100vh"
      bgImage="url('/images/background-pattern.png')" // Set the path to your background image
      bgRepeat="repeat" // Repeats the background image
      bgSize="auto" // Optional: Adjust size if needed, use 'contain', 'cover', or a specific size like '50px'
      bgPosition="center" // Optional: Position background
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        bg="white"
        p={8}
        rounded="md"
        boxShadow="lg"
        maxWidth="500px"
        width="100%"
        textAlign="center"
      >
        <Box mb={8}>
          {/* Add your logo here */}
          <img src="/images/logo-base.svg" alt="Logo" style={{ margin: '0 auto', width: '150px' }} />
        </Box>  
        <VStack spacing={4} align="stretch">
        <Heading as="h1" size="xl" color="brand.600">
            A new way to brew!
          </Heading>
          <Ziggy />
         
          {/*<FormControl> 
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
          </FormControl>
          <Button colorScheme="teal" onClick={handleSignUp} width="100%">
            Sign Up
          </Button>
          */}
          <Text>
            Are you a master tester?{' '}
            <Link color="teal.500" href="/login">
              Log in
            </Link>
          </Text>
        </VStack>
      </Box>
    </Box>  
       <Footer/>
    </>
  );
};

export default SignUp;
