import { Box, Heading, Text, Link, Stack } from '@chakra-ui/react';
import NavBar from './NavBar';  // Assuming you have a NavBar component
import Footer from './Footer';  // Assuming you have a Footer component

function PrivacyPolicy() {
  return (
    <>
      <NavBar />
      <Box maxW="1200px" mx="auto" px={4} py={10}>
        <Heading as="h1" mb={6}>Privacy Policy</Heading>
        <Stack spacing={6}>
          <Text>
            <strong>Effective Date:</strong> [Insert Date]
          </Text>

          <Text>
            At Quantum Brew, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of information we collect, how we use it, and the steps we take to safeguard it.
          </Text>

          <Heading as="h2" size="md">1. Information We Collect</Heading>
          <Text>
            When you sign up for Quantum Brew, we may collect the following information:
          </Text>
          <Text>
            - <strong>Personal Information:</strong> Your name, email address, and any other information you choose to provide when creating an account.
            <br/>
            - <strong>Recipe Data:</strong> The recipes you create and share on the platform.
            <br/>
            - <strong>Usage Data:</strong> Information about how you interact with the site (such as pages viewed, links clicked, and general activity).
          </Text>

          <Heading as="h2" size="md">2. How We Use Your Information</Heading>
          <Text>
            We use the information you provide to:
            <br/>- Enable you to create and share beer recipes.
            <br/>- Improve and optimize the site's functionality and user experience.
            <br/>- Communicate with you about updates or relevant information related to your account.
          </Text>

          <Heading as="h2" size="md">3. Data Sharing</Heading>
          <Text>
            We do <strong>not</strong> sell, trade, or otherwise transfer your personal information to third parties. Your data is securely stored and only used within the platform for the purposes described above.
          </Text>

          <Heading as="h2" size="md">4. Security of Your Information</Heading>
          <Text>
            We take reasonable steps to ensure that your data is protected against unauthorized access, alteration, or destruction. However, no online platform can guarantee 100% security, and you use the site at your own risk.
          </Text>

          <Heading as="h2" size="md">5. Cookies</Heading>
          <Text>
            We may use cookies or similar technologies to improve your experience on the site. You can adjust your browser settings to refuse cookies, but this may affect how you experience the site.
          </Text>

          <Heading as="h2" size="md">6. Changes to This Privacy Policy</Heading>
          <Text>
            Quantum Brew reserves the right to update this Privacy Policy at any time. We will notify you of any significant changes by posting the new policy on this page.
          </Text>

          <Heading as="h2" size="md">7. Contact Information</Heading>
          <Text>
            If you have any questions or concerns about this Privacy Policy, please contact us at: 
            <Link href="mailto:craftsman@quantumbrew.beer" color="teal.500"> craftsman@quantumbrew.beer</Link>
          </Text>
        </Stack>
      </Box>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
