// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage


// Ensure your Firebase configuration is correct
const firebaseConfig = {
    apiKey: "AIzaSyCiToM6Vcfnc3jwO4bgKX--dXwr5jo14kM",
    authDomain: "beer-brewz.firebaseapp.com",
    databaseURL: "https://beer-brewz-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "beer-brewz",
    storageBucket: "beer-brewz.appspot.com",
    messagingSenderId: "708544238851",
    appId: "1:708544238851:web:700f0180dc0b0ec22b8536",
    measurementId: "G-N1NBGHBQYQ"
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const firestore = getFirestore(app);
// const storage = getStorage(app); // Initialize Firebase Storage
const storage = getStorage(app, "gs://beer-brewz.appspot.com"); // Make sure you're using the correct bucket
// Export the services for use in other parts of your app
export { auth, firestore, storage };
