
// src/components/NavBar.js
import React, { useState, useEffect } from 'react';
import { Box, Flex, Link, Text, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Logout from './Logout';


const NavBar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [navBarHeight, setNavBarHeight] = useState("80px"); // Default height

  // Track the scroll position
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    if (position > 50) {
      setNavBarHeight("40px"); // Reduced height when scrolling down
    } else {
      setNavBarHeight("80px"); // Default height when at top
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="1000"
      transition="height 0.3s ease"
      height={navBarHeight} // Adjust height dynamically
      bg="white"
      shadow="md"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px="6"
        height="100%" // Ensure content fits within the navbar
      >
        <Text fontSize="2xl">
          Quantum BREW
        </Text>
        <Link as={RouterLink} to="/" _hover={{ textDecoration: 'none' }}>
          Home
        </Link>
        <Link as={RouterLink} to="/profile" _hover={{ textDecoration: 'none' }}>
          My profile
        </Link>
        <Link as={RouterLink} to="/about" _hover={{ textDecoration: 'none' }}>
          About
        </Link>
        <Link as={RouterLink} to="/all-beer-recipes" _hover={{ textDecoration: 'none' }}>
          Recipe List
        </Link>
        <Link as={RouterLink} to="/beer-recipe" _hover={{ textDecoration: 'none' }}>
          New Recipe
        </Link>
      </Flex>
    </Box>
  );
};

export default NavBar;

