import React from 'react';
import {
  Box,
  Heading,
  Input,
  Button,
  VStack,
  Text,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

const Ziggy = () => {
  return (
    <Box
      id="mc_embed_signup"
      p={6}
    
      maxW="600px"
      mx="auto"
    >
      <form
        action="https://beer.us20.list-manage.com/subscribe/post?u=56dc3e7a1674d11042318c1cf&amp;id=d09d6dc1f6"
        method="post"
        target="_self"
        noValidate
      >
        <VStack spacing={4}>
          {/* Email Field */}
          <FormControl isRequired>
            <FormLabel htmlFor="mce-EMAIL">Email</FormLabel>
            <Input
              type="email"
              id="mce-EMAIL"
              name="EMAIL"
              placeholder="Enter your email and find out"
              size="lg"
              variant="outline"
              focusBorderColor="teal.400"
              required
            />
          </FormControl>

          {/* Hidden Bot Protection Field */}
          <Input
            type="text"
            name="b_56dc3e7a1674d11042318c1cf_d09d6dc1f6"
            tabIndex="-1"
            value=""
            aria-hidden="true"
            style={{ display: 'none' }}
          />

          {/* Subscribe Button */}
          <Button
            type="submit"
            size="lg"
            variant="solid"
            width="full"
            background="brand.600"
            id="mc-embedded-subscribe"
          >
            Subscribe
          </Button>
        </VStack>
      </form>
      <Text fontSize="sm" color="gray.600" mt={4} textAlign="center">
        By subscribing, you agree to receive email updates from Quantum Brew.
      </Text>
    </Box>
  );
};

export default Ziggy;
