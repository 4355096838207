// CalculatorDrawer.js
import React, { useState } from 'react';
import { 
  Drawer, 
  DrawerOverlay, 
  DrawerContent, 
  DrawerHeader, 
  DrawerBody, 
  DrawerCloseButton, 
  Button, 
  IconButton, 
  VStack, 
  Input, 
  Select, 
  Text 
} from '@chakra-ui/react';
import { CalculatorIcon, RepeatClockIcon } from '@chakra-ui/icons'; 

const CalculatorDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  // State for ABV calculator
  const [og, setOg] = useState('');
  const [fg, setFg] = useState('');
  const [abv, setAbv] = useState('');

  // State for IBU calculator
  const [alphaAcid, setAlphaAcid] = useState('');
  const [boilTime, setBoilTime] = useState('');
  const [ibu, setIbu] = useState('');

  // State for Priming Sugar calculator
  const [batchVolume, setBatchVolume] = useState('');
  const [carbonationLevel, setCarbonationLevel] = useState('');
  const [primingSugar, setPrimingSugar] = useState('');

  // State for Mash pH calculator
  const [waterPH, setWaterPH] = useState('');
  const [grainPH, setGrainPH] = useState('');
  const [mashPH, setMashPH] = useState('');

  // State for Yeast Pitch Rate calculator
  const [pitchRate, setPitchRate] = useState('');
  const [yeastAmount, setYeastAmount] = useState('');

  const toggleDrawer = () => setIsOpen(!isOpen);

  // Calculation logic for ABV
  const calculateAbv = () => {
    const abvResult = ((og - fg) * 131.25).toFixed(2);
    setAbv(abvResult);
  };

  // Calculation logic for IBU
  const calculateIbu = () => {
    const utilization = (alphaAcid * boilTime) / 100;
    const ibuResult = (utilization * 75).toFixed(2); // Simplified IBU formula
    setIbu(ibuResult);
  };

  // Calculation logic for Priming Sugar
  const calculatePrimingSugar = () => {
    const sugarPerGallon = carbonationLevel === 'high' ? 4 : carbonationLevel === 'medium' ? 3 : 2;
    const sugarResult = (batchVolume * sugarPerGallon).toFixed(2);
    setPrimingSugar(sugarResult);
  };

  // Calculation logic for Mash pH
  const calculateMashPH = () => {
    const mashPHResult = ((parseFloat(waterPH) + parseFloat(grainPH)) / 2).toFixed(2);
    setMashPH(mashPHResult);
  };

  // Calculation logic for Yeast Pitch Rate
  const calculateYeastPitch = () => {
    const yeastResult = (pitchRate * batchVolume).toFixed(2);
    setYeastAmount(yeastResult);
  };

  return (
    <>
      <IconButton 
        icon={<RepeatClockIcon />} 
        onClick={toggleDrawer} 
        aria-label="Open Calculator"
        colorScheme="blue"
      />
      <Drawer placement="left" isOpen={isOpen} onClose={toggleDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Beer Brewing Calculator</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4}>
              {/* ABV Calculator */}
              <Text fontWeight="bold">ABV Calculator</Text>
              <Input 
                placeholder="Original Gravity (OG)" 
                value={og} 
                onChange={(e) => setOg(e.target.value)} 
              />
              <Input 
                placeholder="Final Gravity (FG)" 
                value={fg} 
                onChange={(e) => setFg(e.target.value)} 
              />
              <Button onClick={calculateAbv} colorScheme="blue">Calculate ABV</Button>
              <Text>ABV: {abv}%</Text>

              {/* IBU Calculator */}
              <Text fontWeight="bold">IBU Calculator</Text>
              <Input 
                placeholder="Alpha Acid (%)" 
                value={alphaAcid} 
                onChange={(e) => setAlphaAcid(e.target.value)} 
              />
              <Input 
                placeholder="Boil Time (minutes)" 
                value={boilTime} 
                onChange={(e) => setBoilTime(e.target.value)} 
              />
              <Button onClick={calculateIbu} colorScheme="blue">Calculate IBU</Button>
              <Text>IBU: {ibu}</Text>

              {/* Priming Sugar Calculator */}
              <Text fontWeight="bold">Priming Sugar Calculator</Text>
              <Input 
                placeholder="Batch Volume (gallons)" 
                value={batchVolume} 
                onChange={(e) => setBatchVolume(e.target.value)} 
              />
              <Select 
                placeholder="Carbonation Level" 
                value={carbonationLevel} 
                onChange={(e) => setCarbonationLevel(e.target.value)}
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </Select>
              <Button onClick={calculatePrimingSugar} colorScheme="blue">Calculate Sugar</Button>
              <Text>Priming Sugar: {primingSugar} oz</Text>

              {/* Mash pH Calculator */}
              <Text fontWeight="bold">Mash pH Calculator</Text>
              <Input 
                placeholder="Water pH" 
                value={waterPH} 
                onChange={(e) => setWaterPH(e.target.value)} 
              />
              <Input 
                placeholder="Grain pH" 
                value={grainPH} 
                onChange={(e) => setGrainPH(e.target.value)} 
              />
              <Button onClick={calculateMashPH} colorScheme="blue">Calculate Mash pH</Button>
              <Text>Mash pH: {mashPH}</Text>

              {/* Yeast Pitch Rate Calculator */}
              <Text fontWeight="bold">Yeast Pitch Rate Calculator</Text>
              <Input 
                placeholder="Pitch Rate (million cells/mL)" 
                value={pitchRate} 
                onChange={(e) => setPitchRate(e.target.value)} 
              />
              <Input 
                placeholder="Batch Volume (gallons)" 
                value={batchVolume} 
                onChange={(e) => setBatchVolume(e.target.value)} 
              />
              <Button onClick={calculateYeastPitch} colorScheme="blue">Calculate Pitch Rate</Button>
              <Text>Yeast Amount: {yeastAmount} million cells</Text>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CalculatorDrawer;
