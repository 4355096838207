// src/components/Home.js
import React from 'react';
import { Button, Box, Heading } from '@chakra-ui/react';
import NavBar from './NavBar';

const Home = () => {
  return (
    <Box p={5}>
      <NavBar />
      <Heading as="h1" size="xl" mb={6}>
        Welcome to Beer Brewing App!
      </Heading>
      <Button colorScheme="teal" size="lg">
        Get Started
      </Button>
    </Box>
  );
};

export default Home;
