import React, { useState, useEffect } from 'react';
import {
  Box, Text, Input, Button, FormControl, FormLabel, VStack, Heading, Avatar, Textarea, HStack, IconButton, Flex
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { auth, firestore, storage } from '../firebaseConfig'; // Ensure Firebase is correctly imported
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase storage for image upload
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'; // Firestore to save and fetch the image URL
import NavBar from './NavBar';

const Profile = () => {
  const [email, setEmail] = useState(''); // User email
  const [avatarUrl, setAvatarUrl] = useState(''); // Avatar URL from Firebase Storage
  const [firstName, setFirstName] = useState(''); // First Name
  const [lastName, setLastName] = useState(''); // Last Name
  const [bio, setBio] = useState(''); // User Bio
  const [file, setFile] = useState(null); // File to upload
  const [isEditing, setIsEditing] = useState(false); // Edit state
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [changesMade, setChangesMade] = useState(false); // Track if changes are made

  useEffect(() => {
    // Fetch the user's profile info (first name, last name, bio, etc.) from Firestore when the page loads
    const fetchProfile = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          setEmail(user.email); // Set user email

          const profileRef = doc(firestore, 'users', user.uid); // Reference to user's profile
          const profileSnap = await getDoc(profileRef); // Fetch the document

          if (profileSnap.exists()) {
            const data = profileSnap.data();
            setAvatarUrl(data.avatarUrl || ''); // Set avatar URL if it exists
            setFirstName(data.firstName || ''); // Set first name if it exists
            setLastName(data.lastName || ''); // Set last name if it exists
            setBio(data.bio || ''); // Set bio if it exists
          } else {
            // If document doesn't exist, create it
            await setDoc(profileRef, {
              email: user.email, // Initialize document with email
              avatarUrl: '',
              firstName: '',
              lastName: '',
              bio: '',
            });
            console.log('Profile document created for user:', user.uid);
          }
        } else {
          setError('User not authenticated');
        }
      } catch (err) {
        setError('Failed to load profile data: ' + err.message);
      }
    };

    fetchProfile(); // Call the function on page load
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setChangesMade(true);

    // Preview the avatar before saving
    const reader = new FileReader();
    reader.onloadend = () => {
      setAvatarUrl(reader.result);
    };
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleFileUpload = async () => {
    if (!file) return;

    try {
      setLoading(true);
      const user = auth.currentUser;
      if (!user) throw new Error('User is not authenticated.');

      // Create a reference in Firebase Storage
      const storageRef = ref(storage, `avatars/${user.uid}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef); // Get download URL
      setAvatarUrl(downloadURL); // Set avatar URL for display

      // Save the avatar URL to Firestore
      const profileRef = doc(firestore, 'users', user.uid);
      await updateDoc(profileRef, {
        avatarUrl: downloadURL,
      });

      setMessage('Avatar uploaded successfully!');
    } catch (err) {
      setError('Error uploading avatar: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const user = auth.currentUser;
      if (!user) throw new Error('User is not authenticated.');

      const profileRef = doc(firestore, 'users', user.uid);
      await updateDoc(profileRef, {
        firstName,
        lastName,
        bio,
      });

      if (file) {
        await handleFileUpload(); // If a new file is selected, upload it
      }

      setMessage('Profile updated successfully!');
      setIsEditing(false); // Switch to non-editing state
      setChangesMade(false); // Reset changes flag
    } catch (err) {
      setError('Error updating profile: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false); // Revert to non-editing state
    setChangesMade(false); // Reset changes flag
  };

  return (
    <Box p={5}>
      <NavBar /> {/* Include the navigation bar */}

      <Flex gap={10}>
        {/* Left Column: Avatar */}
        <Box maxW="280px">
          <Avatar size="2xl" src={avatarUrl} mb={4} /> {/* Show Avatar */}
          {isEditing && (
            <Button
              onClick={() => document.getElementById('avatarUpload').click()}
              colorScheme="teal"
              mb={4}
              isDisabled={loading}
            >
              Select Image
            </Button>
          )}
          <Input
            id="avatarUpload"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            display="none" // Hidden input for file selection
          />
        </Box>

        {/* Right Column: Profile Info */}
        <VStack spacing={4} align="start" flex={1}>
          {/* Name and Edit Button */}
          <HStack justify="space-between" width="100%">
            {isEditing ? (
              <HStack spacing={4} align="center" width="100%">
                <Input
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setChangesMade(true);
                  }}
                  isDisabled={loading}
                  placeholder="First Name"
                />
                <Input
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setChangesMade(true);
                  }}
                  isDisabled={loading}
                  placeholder="Last Name"
                />
              </HStack>
            ) : (
              <Heading as="h1">{firstName} {lastName}</Heading>
            )}

            {!isEditing ? (
              <IconButton
                icon={<EditIcon />}
                onClick={() => setIsEditing(true)}
                colorScheme="teal"
                aria-label="Edit Profile"
              />
            ) : (
              <HStack spacing={2}>
                <Button
                  onClick={handleSubmit}
                  colorScheme="teal"
                  isDisabled={!changesMade || loading}
                >
                  Apply Changes
                </Button>
                <Button onClick={handleCancel} colorScheme="gray">
                  Cancel
                </Button>
              </HStack>
            )}
          </HStack>

          {/* Email */}
          <FormControl>
            <FormLabel>Email</FormLabel>
            {isEditing ? (
              <Input type="email" value={email} isReadOnly />
            ) : (
              <Text fontSize="lg">{email}</Text>
            )}
          </FormControl>

          {/* Bio */}
          <FormControl>
            <FormLabel>Bio</FormLabel>
            {isEditing ? (
              <Textarea
                value={bio}
                onChange={(e) => {
                  setBio(e.target.value);
                  setChangesMade(true);
                }}
                isDisabled={loading}
              />
            ) : (
              <Text fontSize="lg">{bio}</Text>
            )}
          </FormControl>
        </VStack>
      </Flex>

      {/* Messages */}
      {message && <Text color="green.500">{message}</Text>}
      {error && <Text color="red.500">{error}</Text>}
    </Box>
  );
};

export default Profile;
