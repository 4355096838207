import { Box, Heading, Text, Link, Stack } from '@chakra-ui/react';
import NavBar from './NavBar';   
import Footer from './Footer';   

function TermsAndConditions() {
  return (
    <>
      <NavBar />
      <Box maxW="1200px" mx="auto" px={4} py={10}>
        <Heading as="h1" mb={6}>Terms and Conditions</Heading>
        <Stack spacing={6}>
          <Text>
            <strong>Effective Date:</strong> [Insert Date]
          </Text>

          <Text>
            These Terms and Conditions govern your use of the Quantum Brew website. By accessing or using our site, you agree to comply with and be bound by these terms.
          </Text>

          <Heading as="h2" size="md">1. Account Registration</Heading>
          <Text>
            To create and share beer recipes, you must create an account. You are responsible for keeping your login credentials secure. Quantum Brew will not be liable for any loss or damage arising from unauthorized use of your account.
          </Text>

          <Heading as="h2" size="md">2. User Content</Heading>
          <Text>
            You retain ownership of the beer recipes and content you create on the site. By submitting your recipes, you grant Quantum Brew a non-exclusive, worldwide license to display, distribute, and promote your content as part of the site's functionality.
          </Text>

          <Heading as="h2" size="md">3. Prohibited Activities</Heading>
          <Text>
            You agree not to:
            <br/>- Post content that is harmful, illegal, or violates the rights of others.
            <br/>- Attempt to hack, disrupt, or interfere with the website’s functionality.
            <br/>- Use the site for any commercial purposes without prior approval from Quantum Brew.
          </Text>

          <Heading as="h2" size="md">4. Disclaimer of Warranties</Heading>
          <Text>
            Quantum Brew is provided on an "as-is" basis. We make no warranties or guarantees regarding the accuracy, reliability, or completeness of any content on the site. Your use of the site is at your own risk.
          </Text>

          <Heading as="h2" size="md">5. Limitation of Liability</Heading>
          <Text>
            Quantum Brew is not liable for any damages that result from your use or inability to use the site. This includes, but is not limited to, direct, indirect, incidental, or consequential damages.
          </Text>

          <Heading as="h2" size="md">6. Changes to These Terms</Heading>
          <Text>
            Quantum Brew reserves the right to modify these Terms and Conditions at any time. Continued use of the site constitutes acceptance of the updated terms.
          </Text>

          <Heading as="h2" size="md">7. Governing Law</Heading>
          <Text>
            These terms are governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
          </Text>

          <Heading as="h2" size="md">8. Contact Information</Heading>
          <Text>
            For questions or concerns about these Terms and Conditions, please contact us at: 
            <Link href="mailto:craftsman@quantumbrew.beer" color="teal.500">craftsman@quantumbrew.beer</Link>
          </Text>
        </Stack>
      </Box>
      <Footer />
    </>
  );
}

export default TermsAndConditions;
