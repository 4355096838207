import React from 'react';
import {
  Box, Link, Heading, Text, VStack, Container, Image
} from '@chakra-ui/react';
import NavBar from './NavBar';
import Footer from './Footer';
const About = () => {
  return (
    <Box minHeight="100vh" bg="gray.50">
      <NavBar />
      {/* Full-Width Header Image */}
      <Box bg="gray.800" width="100%" position="relative" maxHeight="228px">
        <Image
          src="/images/heading-background-default.jpg" // Path to your header image
          alt="About Header"
          objectFit="cover"
          width="100%"
          maxHeight="228px"
        />
        <Heading as="h1" color="white" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          About Quantum Brew
        </Heading>
      </Box>

      <Container maxW="container.lg" py={10}>
        <VStack spacing={8} align="stretch">
          {/* Blurb 1: A new place for craftsmen */}
          <Box>
            <Heading as="h2" size="lg" mb={4}>A new place for craftsmen</Heading>
            <Text fontSize="md">
              Quantum Brew is out to create a place for passionate brewers. This is a passion project, and we’re just getting started.
              We’re all about building a community of craftsmen who share our love for brewing.
              We'd love to hear your feedback and ideas, so don’t hesitate to get in touch with us at
              <Link href="mailto:craftsman@quantumbrew.beer" color="teal.500">
        craftsman@quantumbrew.beer
      </Link>
            </Text>
          </Box>

          {/* Blurb 2: Created by craftsmen for craftsmen */}
          <Box>
            <Heading as="h2" size="lg" mb={4}>Created by craftsmen for craftsmen</Heading>
            <Text fontSize="md">
              At Quantum Brew, our mission is simple: to help you record and share your beer recipes. This platform is built for 
              brewsters and brewers alike. It’s completely free and designed to be a space where you can showcase your craft.
              Whether you’re new to brewing or a seasoned pro, you’ll find a community here. 
            </Text>
          </Box>

          {/* Blurb 3: Track your brews */}
          <Box>
            <Heading as="h2" size="lg" mb={4}>Track your brews</Heading>
            <Text fontSize="md">
              Our app allows you to not only create recipes but also to track your brews and make notes about the process and outcomes.
              Keep a record of how each batch turned out and improve on your techniques.
              With Quantum Brew, you can experiment, track, and perfect your craft.
            </Text>
          </Box>
        </VStack>
      </Container>
      <Footer/>
    </Box>
  );
};

export default About;
